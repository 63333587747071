<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <b-img
        :src="currentLocale.img"
        height="14px"
        width="22px"
        :alt="currentLocale.locale"
      />
      <span class="ml-50 text-body">{{ currentLocale.name }}</span>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="changeLocale(localeObj.locale)"
    >
      <b-img
        :src="localeObj.img"
        height="14px"
        width="22px"
        :alt="localeObj.locale"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'
import common from "@/common";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.locales.find(l => l.locale === common.getLocale())
    },
  },
  data() {
    return {
      locales: [
        {
          locale: 'zh_TW',
          img: require('@/assets/images/flags/hk.png'),
          name: '繁體中文',
        },
        // {
        //   locale: 'zh_CN',
        //   img: require('@/assets/images/flags/ch.png'),
        //   name: '简体中文',
        // },
        {
          locale: 'en',
          img: require('@/assets/images/flags/en.png'),
          name: 'English',
        },
      ]
    }
  },
  methods: {
    changeLocale: function (locale) {
      common.setLocale(locale)
      common.showLoading()

      common.refreshMenu(common.getRole().facilityID).then(res => {
        location.reload()
      })

    }
  }
}
</script>

<style>

</style>
