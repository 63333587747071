import { render, staticRenderFns } from "./impersonate.vue?vue&type=template&id=7636ffca&scoped=true&"
import script from "./impersonate.vue?vue&type=script&lang=js&"
export * from "./impersonate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7636ffca",
  null
  
)

export default component.exports